.speakers {
  margin: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.chiefguest {
  background-color: rgb(207, 227, 234);
  padding-top: 20px;
}

.chief {
  padding-bottom: 0 !important;
}

.chief-speakercont {
  width: 100%;
  margin-top: 30px;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.speakercont {
  width: 100%;
  margin-top: 100px;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.onespeaker {
  margin-bottom: 75px;
  flex-basis: 40%;

  display: flex;
  flex-direction: column;
  align-items: center;

  text-align: center;
}

.speakerimg {
  width: 150px;
  aspect-ratio: 1;
  object-fit: cover;
  object-position: top;
  border-radius: 100px;
  margin-bottom: 10px;
}

.chief-speakerimg {
  width: 250px;
  aspect-ratio: 1;

  object-fit: cover;
  object-position: top;
  margin-bottom: 10px;
  border: 5px solid black;
}

@media screen and (max-width: 600px) {
  /* .speakercont {
    scale: 0.5;
  } */
}
