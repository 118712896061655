.six {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.8)),
    url(./bg6.png);
  background-color: none;
  background-repeat: no-repeat;
  width: 100%;
  background-size: 100% 100%;
  padding-top: 100px;
  padding-bottom: 100px;
  margin: 0;
  color: white;
  text-align: left;
}

.six h3 {
  text-align: center;
}
.six p {
  padding-top: 10px;
  text-align: center;
  width: 100%;
  font-size: 1.2rem;
}

@media screen and (max-width: 600px) {
  .six {
    overflow-x: hidden;
    /* background: none; */
    background-color: gray;
    width: 100vw;
  }
  .six h3 {
    padding: 25px;
    color: black;
    font-size: 1.9rem;
  }

  .six p {
    padding: 30px;
  }
}
