.abs-para {
  margin: 20px;
  font-size: 1rem;
}
.maroon-abs {
  color: black;
}
.abs-topics {
  margin: 20px;
}
.abs-center {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.abs-gray {
  padding: 20px;
}
.abs-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pdf,
.abs-btn {
  width: 90%;
  padding: 20px;
  background-color: #450808;
  align-items: center;
  text-decoration: none;
  color: white;
  border-radius: 11px;
  display: flex;
  justify-content: center;
}

.abs-btn {
  margin-left: 10px;
}

.abs-none {
  text-decoration: none;
  color: white;
  width: 100%;
}

.abs-journal {
  margin: 20px;
}
.abs-index {
  margin: 20px;
}

.index-img {
  max-height: 70px;
}

.abs-Template {
  width: 100%;
}

.index-images {
  display: flex;
}

.journals {
  columns: 4;
  margin-top: 30px;
}

.journal-images {
  max-height: 150px;
}

.journals p {
  min-height: 90px;
}
.scopus {
  margin-top: 20px;
  margin-left: 20px;
  max-height: 60px;
}

.first-journal {
  max-height: 140px;
}
@media screen and (max-width: 600px) {
  .index-img {
    max-height: 40px;
  }
  .abstract {
    overflow: hidden;
  }
  .abs-links {
    width: 60%;
    padding-right: 30pxs;
  }
  .abs-btn {
    width: 100%;
  }
  .journal-images {
    max-height: 130px;
  }
  .abs-journal {
    margin: 20px;
  }

  .journals {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: auto;
  }

  .j1,
  .j2,
  .j3,
  .j4 {
    margin-bottom: 20px;
  }
}
