.committee {
  background-color: #ffffff;
}

.patrons {
  display: flex;
  justify-content: space-around;
  z-index: -100;
}

.patrons::after {
  position: absolute;
  width: 100%;
  height: 70px;
  content: " ";
  align-self: center;
  justify-self: center;
  background-color: rgb(0, 97, 0);
  z-index: 0;
}

.patron {
  height: 400px;
  aspect-ratio: 1;
  background-color: lightgray;
  margin-top: 120px;
  margin-bottom: 110px;
  padding-top: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.patronname {
  margin: 0px;
  padding: 00px;
  margin-top: 20px;
}

.patronhead {
  font-family: "Times New Roman", Times, serif;
  margin: 0;
  color: orangered;
  margin-top: 5px;
}

.orgcom {
  background-color: lightgray;
  width: 100%;
  display: flex;
  padding: 50px 100px;
  border-radius: 10px;
  flex-wrap: wrap;
}

.corehead {
  flex-basis: 100%;
  margin-top: 50px;
  color: #7a1010;
}

.orgcommembers {
  width: calc(100%-200px);
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.orgcommember {
  display: flex;
  align-items: center;
  flex-grow: 1;
  margin-top: 50px;
  flex-basis: 50%;
}

.secs {
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.orgsecs {
  flex-basis: 33%;
  gap: 3px;
}

.color-role {
  color: #7a1010;
}

.orgcommemberimg {
  width: 75px;
  aspect-ratio: 1;
  border-radius: 100px;
  margin-right: 20px;
  box-shadow: 0 5px 10px #00000099;
  object-fit: cover;
  object-position: top;
}

.secsimg {
  width: 150px;
  margin-right: 0;
}

.othercom {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 50px;
}

.othercom1 {
  width: max(30%, 300px);
  flex-grow: 1;
  background-color: lightgrey;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0px;
  margin: 100px 0px;
  border-radius: 10px;
}
.othercom1 > h3 {
  margin-bottom: 50px;
}

.post {
  margin-top: 30px;
  color: #7a1010;
}

.committee-item {
  margin-left: 30px;
}

.department-adv .name-adv {
  color: black;
}
.name-department {
  color: black;
}

.v-center {
  display: flex;
  flex-direction: column;
  justify-content: center;

  height: 100%;
}

.v-center > h6,
p {
  margin: 0;
}

.othercom1 h5,
.othercom1 p {
  text-align: left !important;
}

.othercom1 .name {
  text-align: left;
}

@media screen and (max-width: 600px) {
  .committee {
    max-width: 100vw;
    overflow-x: hidden;
  }
  body {
    overflow-x: hidden;
  }
  .patrons {
    max-width: 100vw;
    scale: 0.5;
  }
  .patron {
    max-width: 100vw;
  }
}
