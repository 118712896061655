.hero {
  height: 100vh;
  width: 100%;
  background-color: cadetblue;
  display: flex;
  z-index: -100;
  background: linear-gradient(90deg, #808000 -16.68%, #20f3c9 142%);
  display: flex;
  padding-top: 1px;
  flex-direction: column;
}

.new-blinking {
  max-width: 7%;
}

.marquee-container {
  position: absolute;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  margin-top: -1px;
}

.marquee-text {
  display: inline-block;
  animation: marquee 25s linear infinite;
  color: rgb(4, 65, 87);
  background-color: white;
  color: rgb(226, 56, 192);
  padding-left: 10px;
  padding-right: 10px;
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
.hero-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
}

.hero-left {
  /* background: linear-gradient(90deg, #808000 -16.68%, #20f3c9 142%); */

  height: 100%;
  width: 70%;
  z-index: 200;

  padding-left: 50px;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.hero-right {
  margin-top: -24px;
  width: 60%;
  height: 100%;
  background-color: teal;
  position: absolute;
  right: 0;
  z-index: 100;
  clip-path: polygon(50% 0, 100% 0%, 100% 100%, 0% 100%);

  background-image: url("./hero.png");
  background-position: left;
  background-size: cover;
}

.hero-text {
  color: white;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 100;
  margin-top: -100px;
}

.hero-head {
  font-size: 80px;
  margin-bottom: 10px;
  padding-top: 30px;
}

.hero-reg {
  height: 50px;
  width: 200px;
  border-radius: 10px;
  border: 0px solid;
  background-color: rgb(0, 0, 0);
  box-shadow: 0px 5px 10px 0px #00000077;
  color: white;
  font-size: 18px;
}

.wave {
  position: absolute;
  width: 100%;
  bottom: -84px;
  z-index: 500;
  object-fit: cover;
}

.blink {
  text-transform: translate(-50%, -50%);
}

.hero span {
  animation: blink 3s linear infinite;
  color: #ffffff;
}
.journal span {
  animation: blink2 1s linear infinite;
  color: #af0103;
}

@keyframes blink {
  0% {
    opacity: 0.4;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}
@keyframes blink2 {
  0% {
    opacity: 0.1;
  }

  50% {
    opacity: 0.8;
  }

  100% {
    opacity: 1;
  }
}

.journal {
  margin-top: 20px;
  width: 60%;
  color: #af0103;
  font-size: 1rem;
}
.hostel-button {
  margin-left: 30px;
  min-width: fit-content;
  padding: 10px 15px;
}

@media screen and (max-width: 600px) {
  body {
    max-width: 100vw;
    margin: 0;
    padding: 0;
  }

  .hero-right {
    display: none;
  }

  .hero {
    display: block;
    margin-top: 0px;
    width: 100vw;
    height: fit-content;
  }
  .hero-content {
    margin-top: 100px;
    width: 80%;
    align-items: center;
    text-align: center;
    padding: 10px;
  }
  .journal {
    margin-top: 40px;
    width: 80%;
    text-align: center;
  }
  .hero-left {
    width: 100%;
    align-items: center;
    text-align: center;
  }
  .hero-head {
    width: 100%;
    font-size: 50px;
  }
  .wave {
    display: none;
  }
  .hostel-button {
    margin: 0;
  }
}
