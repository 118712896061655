.four {
  background-color: lightgrey;
  color: black;
  padding-bottom: 100px;
}

.venuecont{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 50px;
}

.venuetext{
  width: max(40%, 400px);
  text-align: justify;
  align-items: center;
  flex-grow: 1;
}

.venueimage{
  width: max(40%, 400px);
  flex-grow: 1;
  margin-top: 50px;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.four h2 {
  padding-top: 20px;
}

.four h3 {
  padding-top: 40px;
  padding-bottom: 40px;
}